import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

export const QuoteText = styled(Text).attrs({
  as: 'p',
  styleType: 'h2',
  ext: true,
  mb: 2,
})`
  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  }
`;

export const StyledLink = styled.a`
  text-decoration-color: #2d7650;
  color: #2d7650;
`;
