import React from 'react';
import PropTypes from 'prop-types';
import { Text, HideBreakpoint, ShowBreakpoint } from '@strikelabs/luna';

import { Outer } from './style';

const Intro = ({ title, intro }) => (
  <Outer>
    <Text as="h2" mb={4} ext>
      {title}
    </Text>
    <HideBreakpoint breakpoint="desktop">
      <Text as="p" ext>
        {intro.mobile}
      </Text>
    </HideBreakpoint>
    <ShowBreakpoint breakpoint="desktop">
      <Text as="p" ext>
        {intro.desktop}
      </Text>
    </ShowBreakpoint>
  </Outer>
);

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.exact({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
};

export default Intro;
