import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  BlockQuote,
  Highlight,
  Box,
  HideBreakpoint,
  ShowBreakpoint,
  Flex,
  Icon,
} from '@strikelabs/luna';

import useTrustpilotRating from 'hooks/useTrustpilotRating';
import { Outer, QuoteBodyDesktop } from './style';

const Quote = ({ title, quote, author, location }) => {
  const { total } = useTrustpilotRating();
  return (
    <Outer>
      <Flex justifyContent="center">
        <Box width={[1, 1, 1, 1, 8 / 10]}>
          <BlockQuote noTrailingQuotationMark>
            <Box textAlign="center">
              <Text as="h2" ext mb={4}>
                <Highlight>{title}</Highlight>
              </Text>
            </Box>
            <HideBreakpoint breakpoint="desktop">
              <Box textAlign="center">
                <Text as="p" ext mb={[3, 3, 3, 3, 4]}>
                  {quote.mobile}
                </Text>
              </Box>
            </HideBreakpoint>
            <ShowBreakpoint breakpoint="desktop">
              <Box textAlign="center">
                <QuoteBodyDesktop>{quote.desktop}</QuoteBodyDesktop>
              </Box>
            </ShowBreakpoint>
            <Box textAlign="center">
              <Text as="p" styleType="h3" ext mb={3}>
                &mdash;{' ' + author}.{author && <> {location}</>}
              </Text>
              <Flex
                justifyContent="center"
                alignItems="center"
                mb={['0px', '0px', '0px', '0px', -1]}
              >
                <Box mr={1} pb={0}>
                  <Icon type="trustpilotType" width="124px" />
                </Box>
                <Icon type="trustpilotStars" width="94px" />
              </Flex>
              <Text styleType="caption">
                1 of {total} reviews, rated &apos;Excellent&apos;
              </Text>
            </Box>
          </BlockQuote>
        </Box>
      </Flex>
    </Outer>
  );
};

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  quote: PropTypes.exact({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  author: PropTypes.string.isRequired,
  location: PropTypes.string,
};

Quote.defaultProps = {
  location: '',
};

export default Quote;
