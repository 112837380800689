import styled from 'styled-components';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    margin-top: ${({ theme }) => theme.spacing.xxl};
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const StyledLink = styled.a`
  text-decoration-color: #2d7650;
  color: #2d7650;
`;
