import { CHECKLIST as SHARED_CHECKLIST } from './sell-your-home-for-free';

export const QUOTE = {
  mobile:
    'Staff introduced themselves via phone each time they made contact. Confirmation emails and texts at every stage which was very reassuring. Pictures great. Sign up outside within 2 days of advert. Great. Would reccomend them in a heartbeat.',
  desktop: 'Would recommend them in a heartbeat.',
};

export const INTRO = {
  mobile: `Strike is your Manchester estate agent with a difference —
              we'll sell your home for free. Not only that, we love the
              Manchester property market. In fact, Manchester was one of the
              first places Strike decided to call home. We offer everything you
              need to sell your home &mdash; and all the help you need along the
              way — absolutely free. In fact, whether you're buying
              or selling, we think your move should be on your terms. We offer
              floorplans, photos, listings on Rightmove and Zoopla, professional
              offer negotiation, and more — for free. And we've got
              an app and online hub that will let you manage your listing,
              scheduling viewings, and connect directly to buyers.`,
  desktop: `Strike is your Manchester estate agent with a difference —
              we'll sell your home for free. But more importantly, we love
              the Manchester property market. In fact, Manchester was one of the
              first places Strike decided to call home. So you can rest assured
              that we know and love Manchester just as much as you do.`,
};

export const CHECKLIST = SHARED_CHECKLIST;
