import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from '@strikelabs/luna';

import { Outer } from './style';

const ThePropertyMarket = ({ image, city, children }) => {
  return (
    <Outer>
      <Flex alignItems="center" width={1}>
        <Box width={[1, 1, 1, 6 / 10]} pr={['0', '0', '0', 6]}>
          <Text as="h2" ext mb={3}>
            The {city} property market
          </Text>
          {children}
        </Box>
        <Box width={[0, 0, 0, 4 / 10]}>
          <img src={image.src} alt={image.alt} />
        </Box>
      </Flex>
    </Outer>
  );
};

ThePropertyMarket.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  city: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ThePropertyMarket;
