import React from 'react';
import { Text, Highlight } from '@strikelabs/luna';

import { QuoteText, StyledLink } from './style';

const CopyforManchester = () => (
  <>
    <Text as="p" ext mb={2}>
      With music, football, and easy escapes to the Peak District and so much
      more &mdash; there&apos;s a reason we love selling homes here. In fact,
      Manchester was named the UK&apos;s most livable city for a reason (and
      we&apos;re not just talking about the Big Grillie Style burgers).
    </Text>
    <QuoteText>
      <strong>
        <Highlight>
          It&apos;s a great time for the Manchester property market &mdash;
          because it seems like the rest of the country has finally caught up
          with what makes Manchester special.
        </Highlight>
      </strong>
    </QuoteText>
    <Text as="p" ext mb={2}>
      We know that Manchester is a great place to live, whether you&apos;re
      starting your family, looking for a change, or heading into retirement.
      That&apos;s why we help buy and sell homes in the entire Greater
      Manchester area. From Rochdale to the North Quarter, we&apos;ve got you
      covered.
    </Text>
    <Text as="p" ext mb={2}>
      With an{' '}
      <StyledLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.rightmove.co.uk/house-prices/manchester.html"
      >
        average house price in Manchester
      </StyledLink>{' '}
      currently hovering at around &pound;248,704, there are plenty of
      affordable options &mdash; and a lot of luxury properties, depending on
      what you&apos;re looking for. Prices have been going up, so it looks like
      the rest of the country has finally caught up with what makes Manchester
      special.
    </Text>
  </>
);

export default CopyforManchester;
