import React from 'react';
import { Underline, ShowBreakpoint, HideBreakpoint } from '@strikelabs/luna';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Checklist from 'components/Checklist';
import BookVal from 'components/BookVal';
import SavingsSlider from 'components/SavingsSlider';
import SimilarProperties from 'components/SimilarProperties';
import HowWeSellForFree from 'components/HowWeSellForFree';
import { Hero } from 'components/Pages/Homepage/Sections';
import {
  Intro,
  ThePropertyMarket,
} from 'components/Pages/StrikeInLocation/Sections';
import { LifeInManchester } from 'components/Pages/StrikeInLocation/Sections/LifeInCity';
import { Stats, FAQs } from 'components/Pages/EstateAgentsInLocation/Sections';
import ManchesterHero from 'images/manchester-hero.png';
import { INTRO, CHECKLIST } from 'constants/strike-in-manchester';
import CopyForManchester from 'components/Pages/StrikeInLocation/Sections/ThePropertyMarket/Copy/CopyForManchester';
import PalaceTheatre from 'images/palace-theatre.png';

const UnderlinedCity = () => (
  <Underline
    weight="h1"
    lineMultiplier={0.3}
    active
    auto
    delay={1}
    duration={1}
  >
    Manchester
  </Underline>
);

const StrikeInManchester = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Strike - Strike In Manchester"
          description="Get that sell-your-home-for-free feeling in Manchester"
          canonical="/strike-in-manchester"
        />

        <Hero
          subtitle=" "
          heroImage={ManchesterHero}
          backgroundPosition={['bottom', 'center']}
        >
          <>
            <HideBreakpoint breakpoint="desktop">
              Get that sell-
              <br />
              your-home
              <br />
              for-free feeling <br />
              in <UnderlinedCity />
            </HideBreakpoint>
            <ShowBreakpoint breakpoint="desktop">
              Get that sell-your-
              <br />
              home-for-free
              <br /> feeling in <UnderlinedCity />
            </ShowBreakpoint>
          </>
        </Hero>
        <Intro
          title="Manchester: Where the Strike journey began"
          intro={INTRO}
        ></Intro>
        <Stats />
        <SavingsSlider
          title="Strike could save you thousands compared to high street agents"
          defaultValue={0.17}
          calculatorTitle="Go on Strike in Manchester and you could save"
        />
        <Checklist
          title="We're by your side, all the way until the keys are in your hands"
          list={CHECKLIST}
        />
        <HowWeSellForFree />
        <BookVal />
        <ThePropertyMarket
          city="Manchester"
          image={{ src: PalaceTheatre, alt: 'Palace theatre' }}
        >
          <CopyForManchester />
        </ThePropertyMarket>
        <SimilarProperties city="Manchester" id={98410} />
        <LifeInManchester />
        <FAQs />
      </Layout>
    </>
  );
};

export default StrikeInManchester;
