import React from 'react';
import { Box, Text, ShowBreakpoint, HideBreakpoint } from '@strikelabs/luna';

import { Outer, StyledLink } from './style';

const LifeInManchester = () => (
  <Outer>
    <Box width={[1, 1, 1, 1, 8 / 10]}>
      <HideBreakpoint breakpoint="desktop">
        <Text as="h2" mb={4} ext>
          Life in Manchester
        </Text>
        <Text as="p" ext mb={3}>
          Whether you&apos;re looking to grab a bite at the{' '}
          <StyledLink
            href="https://cornexchangemanchester.co.uk/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Corn Exchange
          </StyledLink>{' '}
          or in the{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.visitmanchester.com/shopping/northern-quarter-p25851"
          >
            Northern Quarter
          </StyledLink>
          , or find a gig at{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.thisisgorilla.com/"
          >
            Gorilla
          </StyledLink>
          ,{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://bandonthewall.org/"
          >
            Band on the Wall
          </StyledLink>
          , the{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.alberthallmanchester.com/"
          >
            Albert Hall
          </StyledLink>
          , or{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.yes-manchester.com/"
          >
            YES
          </StyledLink>{' '}
          &mdash; Manchester is knowns for its nightlife, packed with
          independent restaurants and venues.
        </Text>
        <Text as="p" ext mb={3}>
          Of course, the football fans among you may be aware that there are two
          &mdash; yes, two &mdash; major clubs, with{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.mancity.com/"
          >
            Manchester City
          </StyledLink>{' '}
          and{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.manutd.com/"
          >
            Manchester United
          </StyledLink>{' '}
          both calling the area home (and don&apos;t miss the{' '}
          <StyledLink
            href="https://www.nationalfootballmuseum.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            National Football Museum
          </StyledLink>
          , a must for all real fans). If you really want to commit to a team,
          you can find fellow fans based off of where they live. One{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.manchestereveningnews.co.uk/sport/football/derby-day-map-shows-parts-15407574"
          >
            survey
          </StyledLink>{' '}
          showed that those living in the north and west of the city seem to
          favour United, while the south and east were City fans.
        </Text>
        <Text as="h4" bold ext mb={3}>
          <u>A university hub </u>
        </Text>
        <Text as="p" ext>
          Of course, you can&apos;t forget the universities. Manchester has a
          thriving student population, which helps ensure the city&apos;s
          dynamic edge, and{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.manchester.ac.uk/"
          >
            The University of Manchester
          </StyledLink>{' '}
          and{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.mmu.ac.uk/"
          >
            Manchester Metropolitan University
          </StyledLink>{' '}
          both draw students from around the country and around the world.
          There&apos;s also the celebrated{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.rncm.ac.uk/"
          >
            Royal Northern College of Music
          </StyledLink>
          , and other major universities in the area &mdash;{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.salford.ac.uk/  "
          >
            University of Salford
          </StyledLink>
          ,{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.bolton.ac.uk/"
          >
            University of Bolton
          </StyledLink>{' '}
          and{' '}
          <StyledLink
            rel="noopener noreferrer"
            target="_blank"
            href="https://ua92.ac.uk/"
          >
            University Academy 92
          </StyledLink>
          , just to name a few. If you are a student looking to call the area
          home &mdash; or if you&apos;re looking for an investment property to
          let to students &mdash; Fallowfield, Rusholme, Oxford Road, Didsbury
          and East Didsbury are all great bets for young people. As you can see,
          there are a lot of reasons people love to live in Manchester &mdash; a
          vibrant city with a lot of fun and culture on offer, but amazing
          family homes and green space too. Whether you already live in
          Manchester or are looking to call it home, it&apos;s a great choice.
          It&apos;s easy to see why Strike is proud to be a Manchester estate
          agent.
        </Text>
      </HideBreakpoint>
      <ShowBreakpoint breakpoint="desktop">
        <Text as="h2" ext mb={4}>
          A great place to call home
        </Text>
        <Text as="p" ext mb={3}>
          Looking to buy or sell in Manchester? It&apos;s easy to see why. The
          Manchester property market is incredibly buzzy at the moment &mdash;
          it&apos;s shown that it can go from strength to strength as an
          affordable city with great career prospects. (In fact, it showed
          nearly 8% growth year on year in 2021, the second largest in the UK
          according to Zoopla.) We&apos;re seeing even more interest in
          Manchester as remote working has picked up and people have realised
          what Manchester has to offer, so the market is showing no sign of
          slowing.
        </Text>
        <Text as="p" ext mb={3}>
          Great for families
        </Text>
        <Text as="p" ext mb={3}>
          One of the reasons the Manchester market has stayed so strong is
          it&apos;s a perfect fit for young families and couples starting to
          think about schools. Primaries like New Islington Free School, St
          Ann&apos;s RC Primary School, Ashbury Meadow Primary School and Abbott
          Community Primary School, all come with great reviews and high
          ratings, and their secondary schools shine too, making their
          neighbourhoods all the more desirable. There are a range of grammar
          schools— including Manchester Grammar School, William Hulme&apos;s
          Grammar School, Stretford Grammar School and Withington High School
          for Girls &mdash; and outstanding-rated comprehensives like Trinity
          CofE High School, The King David High School, Melland High School and
          Levenshulme High School. These help keep the market thriving.
        </Text>
        <Text as="p" ext mb={3}>
          Perfect for students and investors
        </Text>
        <Text as="p" ext mb={3}>
          Of course, you can&apos;t forget the universities. Whether you&apos;re
          an investor looking for a strong rental option with great yields or a
          young graduate looking to get on the property ladder, you can&apos;t
          talk about the Manchester property market without mentioning the unis.
          Manchester has a thriving student population, which helps ensure the
          city&apos;s dynamic edge, and The University of Manchester and
          Manchester Metropolitan University both draw students from around the
          country and around the world. There&apos;s also the celebrated Royal
          Northern College of Music, and other major universities in the area —
          University of Salford, University of Bolton and University Academy 92,
          just to name a few.
        </Text>
        <Text as="p" ext mb={3}>
          As you can see, there are a lot of reasons people love to live in
          Manchester &mdash; and a lot of reasons Strike is proud to be a
          Manchester estate agent. Plus, more and more people are catching on to
          what Manchester has to offer. An affordable, bustling city with nature
          at its doorstep? No wonder it&apos;s such a strong market.
        </Text>
      </ShowBreakpoint>
    </Box>
  </Outer>
);

export default LifeInManchester;
