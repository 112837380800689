import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    margin-top: ${({ theme }) => theme.spacing.xxl};
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const QuoteBodyDesktop = styled(Text).attrs({
  as: 'p',
  styleType: 'h2',
  ext: true,
  mb: [3, 3, 3, 3, 4],
})`
  font-size: ${rem(32)};
`;
